import React, { useEffect, useState } from 'react'

import Upload from '../Shared/Upload'
import { useAnalyticsPushEvent } from '../../analytics'
import { PostMergeStatementUpload } from '../../utils/api'
import MergeStatementContainer from './MergeStatementContainer'

function App () {
    const pushEvent = useAnalyticsPushEvent()
    const [triggerRefreshTable, setTriggerRefreshTable] = useState(null)

    // Upload.
    const uploadFilePicked = (file, filePickClicked) => {
        pushEvent('Statement Folder picked', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            filePickClicked
        })
    }
    const uploadFileUploaded = (file) => {
        setTriggerRefreshTable(new Date().getTime())

        pushEvent('Statement Folder uploaded', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            }
        })
    }

    // Inits.
    useEffect(() => {
        pushEvent('List Statement Folders')
    }, [pushEvent])

    return (
        <>
            <h2>Rekening Koran (Konsumtif)</h2>
            <Upload
                uploadUrl={PostMergeStatementUpload()}
                filePicked={uploadFilePicked}
                fileUploaded={uploadFileUploaded}
                params={{ type: 'consumptive' }}
                showNotes
            />
            <MergeStatementContainer triggerRefreshTable={triggerRefreshTable} />
        </>
    )
}

export default App
