import React, { useContext, useEffect, useRef, useState } from 'react'

import Axios from 'axios'
import { Card, ProgressBar, Spinner } from 'react-bootstrap'

import SlikUpload from '../Shared/Upload'
import SlikContainer from './SlikContainer'
import AppContext from '../../utils/context'
import CustomModal from '../Shared/CustomModal'
import { useAnalyticsPushEvent } from '../../analytics'
import {
    GetSlikList, PostMergeSlikMerge, PostSlikProcess, PostSlikUpload
} from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem, onProcessCompleted }) {
    const { pushNotification } = useContext(AppContext)
    const pushEvent = useAnalyticsPushEvent()
    const [loading, setLoading] = useState(false)

    // Computed values.
    const hasNotBeenProcessed = () =>
        (selectedItem && !selectedItem.processed_at) ||
        process.env.REACT_APP_MODE_ENV !== 'production'

    // Gets detail slik.
    const [detailSliks, setDetailSliks] = useState([])
    async function fetchFilesSlik (item) {
        if (!item) return

        try {
            setLoading(true)
            const params = { merge_uuid: item.uuid }
            const { data: responseData } = await Axios.get(GetSlikList(), { params })
            setDetailSliks(responseData?.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Upload.
    const [uploadUrl, setUploadUrl] = useState(null)
    const uploadFilePicked = (file, filePickClicked) => {
        pushEvent('Slik File picked in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            filePickClicked
        })
    }
    const uploadFileUploaded = (file) => {
        fetchFilesSlik(selectedItem)

        pushEvent('Slik File uploaded in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            folder: {
                uuid: selectedItem.uuid
            }
        })
    }

    // Processing filters && process.env.REACT_APP_MODE_ENV !== 'production'.
    const listRef = useRef()

    // Process slik.
    const [processing, setProcessing] = useState(false)
    const [processingMax, setProcessingMax] = useState(100)
    const [processingProgress, setProcessingProgress] = useState(0)
    const [selected, setSelected] = useState(null)
    const [uploading, setUploading] = useState(false)

    // Adds sleep.
    const sleep = function (ms = 200) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const handleAction = () => {
        if (!detailSliks?.length) return

        async function fetchData () {
            try {
                const listSliks = detailSliks
                const slikPasswords = listRef.current.getSlikPasswords()
                const mainProcessFn = async function (currentSlik) {
                    const password = slikPasswords?.[currentSlik.uuid]

                    try {
                        // Process.
                        const url = PostSlikProcess(currentSlik.uuid)
                        const processPayload = {
                            password
                        }
                        const { data: responseData } = await Axios.post(url, processPayload)
                        if (responseData?.data?.success) {
                            currentSlik.processed_at = true
                        }

                        return responseData
                    } catch (err) {
                        pushNotification('error', null, err)
                    }
                }

                // Executes sequentially.
                setProcessingProgress(1)
                setProcessingMax(listSliks.length + 2) // one for initial display, one for merge process
                setProcessing(true)
                let progress = 1
                await listSliks.reduce(async (p, value) => {
                    await p

                    await Promise.all([mainProcessFn(value)])

                    // Advances progress.
                    progress += 1
                    setProcessingProgress(progress)
                }, Promise.resolve())

                const url = PostMergeSlikMerge()
                const payload = {
                    uuid: selected.uuid
                }
                const { data: responseData } = await Axios.post(url, payload)

                pushNotification('success', responseData.message)

                // Advances progress.
                progress += 1
                setProcessingProgress(progress)
                await sleep(1000)

                setProcessing(false)
                setShowModal(false)

                // Adds to amplitude.
                pushEvent('Slik Folder processed', {
                    clickedItem: selected
                })

                if (onProcessCompleted && typeof onProcessCompleted === 'function') {
                    onProcessCompleted()
                }
            } catch (err) {
                pushNotification('error', null, err)
                setProcessing(false)
            }
        }

        fetchData()
    }

    // Inits.
    useEffect(() => {
        // Resets first.
        setUploadUrl(null)
        setDetailSliks([])

        if (!selectedItem) return

        fetchFilesSlik(selectedItem)
        setUploadUrl(PostSlikUpload(selectedItem.uuid))
        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('Preprocess Slik Folder', {
            folder: {
                uuid: selectedItem.uuid
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    return (
        <CustomModal
            size="xl"
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Proses Sistem Layanan Informasi Keuangan (SLIK)"
            primaryButtonText={hasNotBeenProcessed() ? 'Proses' : ''}
            primaryButtonAction={handleAction}
            primaryButtonVariant="primary"
            primaryButtonDisabled={!detailSliks?.length}
            secondaryButtonText="Tutup"
            secondaryButtonDisabled={processing || uploading}
            loading={processing || uploading}
            keyboard={!processing && !uploading}
            closeButton={!processing && !uploading}>
            { loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading &&
                (
                    <Card>
                        <Card.Header>Daftar SLIK</Card.Header>
                        <Card.Body>
                            {
                                !processing &&
                                    (<SlikUpload
                                        uploadUrl={uploadUrl}
                                        filePicked={uploadFilePicked}
                                        fileUploaded={uploadFileUploaded}
                                        setUploading={setUploading}
                                    />)
                            }
                            <SlikContainer
                                ref={listRef}
                                mergeSlik={selectedItem}
                                sliks={detailSliks}
                                fetchFilesSlik={fetchFilesSlik}
                                processing={processing || uploading} />
                        </Card.Body>
                    </Card>
                )
            }
            {processing && (<ProgressBar animated now={processingProgress} max={processingMax} className="mt-3" />)}

        </CustomModal>
    )
}

export default App
