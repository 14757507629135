import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass1 as icon1, faHourglass2 as icon2, faHourglass3 as icon3 } from '@fortawesome/free-solid-svg-icons'
import './AnimatedHourGlass.css'

function App ({ size = 'lg', className = 'text-primary' }) {
    return (
        <div className="animated-icon-container">
            <FontAwesomeIcon
                icon={icon1}
                size={size}
                className={`hide-show1 ${className}`}
            />
            <FontAwesomeIcon
                icon={icon2}
                size={size}
                className={`hide-show2 ${className}`}
            />
            <FontAwesomeIcon
                icon={icon3}
                size={size}
                className={`hide-show3 ${className}`}
            />
        </div>
    )
}

export default App
