import React, { useContext, useEffect, useRef, useState } from 'react'

import Axios from 'axios'
import { Card, ProgressBar, Spinner } from 'react-bootstrap'

import AppContext from '../../utils/context'
import StatementUpload from '../Shared/Upload'
import CustomModal from '../Shared/CustomModal'
import StatementContainer from './StatementContainer'
import { useAnalyticsPushEvent } from '../../analytics'
import { GetStatementList, PostMergeStatementMerge, PostStatementPreprocess, PostStatementProcess, PostStatementUpload, PutMergeStatementDashboardStatus } from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem, onProcessCompleted }) {
    const { pushNotification } = useContext(AppContext)
    const pushEvent = useAnalyticsPushEvent()
    const [loading, setLoading] = useState(false)

    // Computed values.
    const hasNotBeenProcessed = () =>
        (selectedItem && !selectedItem.processed_at) ||
        process.env.REACT_APP_MODE_ENV !== 'production'

    // Gets detail statement.
    const [detailStatements, setDetailStatements] = useState([])
    async function fetchFilesStatement (item) {
        if (!item) return

        try {
            setLoading(true)
            const params = { merge_uuid: item.uuid }
            const { data: responseData } = await Axios.get(GetStatementList(), { params })
            setDetailStatements(responseData?.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Upload.
    const [uploadUrl, setUploadUrl] = useState(null)
    const uploadFilePicked = (file, filePickClicked) => {
        pushEvent('Statement File picked in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            filePickClicked
        })
    }
    const uploadFileUploaded = (file) => {
        fetchFilesStatement(selectedItem)

        pushEvent('Statement File uploaded in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            folder: {
                uuid: selectedItem.uuid
            }
        })
    }

    // Processing filters && process.env.REACT_APP_MODE_ENV !== 'production'.
    const listRef = useRef()

    // Process statement.
    const [processing, setProcessing] = useState(false)
    const [processingMax, setProcessingMax] = useState(100)
    const [processingProgress, setProcessingProgress] = useState(100)
    const [selected, setSelected] = useState(null)
    const [uploading, setUploading] = useState(false)

    // Adds sleep.
    const sleep = function (ms = 200) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const handleAction = async () => {
        if (!detailStatements?.length) return

        async function fetchData () {
            try {
                // 1 process statement estimated take 20 seconds.
                //      then 20 sec * 1000 ms / 200 sleep ms
                const perStatementMaxCounter = 20 * 1000 / 200
                let currentStatementTotalCounter = perStatementMaxCounter
                let isBackgroundProcessActive = true
                let currentBackgroundProcessCounter = 0
                let progress = 0
                listRef.current.setStatementErrorFilesMessageTable(null, true)
                const backgroundProcessFn = async () => {
                    // eslint-disable-next-line no-unmodified-loop-condition
                    while (isBackgroundProcessActive && currentBackgroundProcessCounter <= currentStatementTotalCounter) {
                        // eslint-disable-next-line no-await-in-loop
                        await sleep()

                        currentBackgroundProcessCounter += 1
                        progress += 1
                        setProcessingProgress(progress)
                    }
                }

                const listStatements = detailStatements
                let maxProgressCounter = (listStatements.length * perStatementMaxCounter) + 1

                const statementPasswords = listRef.current.getStatementPasswords()
                const mainProcessFn = async function (currentStatement) {
                    const password = statementPasswords?.[currentStatement.uuid]

                    try {
                        // Preprocess.
                        const url0 = PostStatementPreprocess(currentStatement.uuid)
                        const preprocessPayload = {
                            password
                        }
                        const { data: responseData0 } = await Axios.post(url0, preprocessPayload)
                        currentStatementTotalCounter = perStatementMaxCounter * (responseData0?.data?.total_page_numbers || 1)
                        maxProgressCounter = maxProgressCounter + (perStatementMaxCounter * ((responseData0?.data?.total_page_numbers || 1) - 1))
                        setProcessingMax(maxProgressCounter)

                        // Process.
                        const url = PostStatementProcess(currentStatement.uuid)
                        const processPayload = {
                            password
                        }
                        const { data: responseData } = await Axios.post(url, processPayload)
                        if (responseData?.data?.success) {
                            currentStatement.processed_at = true
                        }

                        return responseData
                    } catch (err) {
                        if (!['STPPS45', 'STPPS46'].includes(err?.response?.data?.error_code)) {
                            pushNotification('error', null, err)
                        } else {
                            listRef.current.setStatementErrorFilesMessageTable({
                                uuid: currentStatement.uuid,
                                message: err?.response?.data?.message,
                                errorCode: err?.response?.data?.error_code
                            })
                        }
                    } finally {
                        isBackgroundProcessActive = false
                    }
                }

                setProcessingMax(maxProgressCounter)
                setProcessingProgress(0)
                setProcessing(true)

                // Executes sequentially.
                const results = []
                await listStatements.reduce(async (p, value) => {
                    await p

                    isBackgroundProcessActive = true
                    currentBackgroundProcessCounter = 0
                    const [result] = await Promise.all([mainProcessFn(value), backgroundProcessFn()])

                    // Advances the progress at the rest, if not reach maximum counter
                    const leftover = (currentStatementTotalCounter - currentBackgroundProcessCounter)
                    progress += leftover
                    setProcessingProgress(progress)

                    results.push(result)
                    return result
                }, Promise.resolve())

                const url = PostMergeStatementMerge()
                const payload = {
                    uuid: selected.uuid
                }
                const { data: responseData } = await Axios.post(url, payload)

                progress += 1
                setProcessingProgress(progress)
                await sleep(1500)

                pushNotification('success', responseData.message)
                setProcessing(false)
                setShowModal(false)

                // Adds to amplitude.
                pushEvent('Statement Folder processed', {
                    clickedItem: selected
                })

                if (onProcessCompleted && typeof onProcessCompleted === 'function') {
                    onProcessCompleted()
                }
            } catch (err) {
                pushNotification('error', null, err)
                setProcessing(false)
            }
        }

        try {
            await Axios.put(PutMergeStatementDashboardStatus(selectedItem.uuid))
            await fetchData()
        } catch (err) {
            pushNotification('error', null, err)
        }
    }

    // Inits.
    useEffect(() => {
        // Resets first.
        setUploadUrl(null)
        setDetailStatements([])

        if (!selectedItem) return

        fetchFilesStatement(selectedItem)
        setUploadUrl(PostStatementUpload(selectedItem.uuid))
        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('Preprocess Statement Folder', {
            folder: {
                uuid: selectedItem.uuid
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    return (
        <CustomModal
            size="xl"
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Proses Rekening Koran (Konsumtif)"
            primaryButtonText={hasNotBeenProcessed() ? 'Proses' : ''}
            primaryButtonAction={handleAction}
            primaryButtonVariant="primary"
            primaryButtonDisabled={!detailStatements?.length}
            secondaryButtonText="Tutup"
            secondaryButtonDisabled={processing || uploading}
            loading={processing || uploading}
            keyboard={!processing && !uploading}
            closeButton={!processing && !uploading}>
            { loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && <>
                {
                    (detailStatements?.length > 10 && processing) &&
                            (<ProgressBar animated now={processingProgress} max={processingMax} className="mb-3" />)
                }
                <Card>
                    <Card.Header>Daftar Rekening Koran</Card.Header>
                    <Card.Body>
                        {
                            !processing &&
                                (<StatementUpload
                                    uploadUrl={uploadUrl}
                                    filePicked={uploadFilePicked}
                                    fileUploaded={uploadFileUploaded}
                                    setUploading={setUploading}
                                    params={{ type: 'consumptive' }}
                                />)
                        }
                        <StatementContainer
                            ref={listRef}
                            mergeStatement={selectedItem}
                            statements={detailStatements}
                            fetchFilesStatement={fetchFilesStatement}
                            processing={processing || uploading} />
                    </Card.Body>
                </Card>
            </>}
            {processing && (<ProgressBar animated now={processingProgress} max={processingMax} className="mt-3" />)}

        </CustomModal>
    )
}

export default App
