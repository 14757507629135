import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody } from 'react-bootstrap'

const AccountInfo = () => {
    const [partnerInfo, setPartnerInfo] = useState({
        uuid: '',
        name: '',
        username: ''
    })

    useEffect(() => {
        const rawPartner = localStorage.getItem('partner')
        if (rawPartner) {
            try {
                const partner = JSON.parse(rawPartner)
                if (partner) {
                    setPartnerInfo(partner)
                }
            } catch (err) {
                setPartnerInfo({
                    uuid: '',
                    name: '',
                    username: ''
                })
            }
        }
    }, [])

    return (
        <div className="mt-5">
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <div className="col-md-8">
                                <div className="mb-3">
                                    <p className="card-text">
                                        <strong>Nama:</strong>
                                    </p>
                                    <p>{partnerInfo.name}</p>
                                </div>
                                <div className="mb-3">
                                    <p className="card-text">
                                        <strong>UUID:</strong>
                                    </p>
                                    <p>{partnerInfo.uuid}</p>
                                </div>
                                <div className="mb-3">
                                    <p className="card-text">
                                        <strong>Email:</strong>
                                    </p>
                                    <p>{partnerInfo.username}</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default AccountInfo
