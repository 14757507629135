import React, { useState } from 'react'
import Axios from 'axios'
import Cookies from 'js-cookie'
import { useAnalyticsPushEvent } from '../analytics'
import { useNavigate } from 'react-router-dom'

import {
    PostAuthUserLogout,
    GetUserDetail
} from '../utils/api'

const AuthContext = React.createContext()

const initializeUser = {
    uuid: '',
    name: '',
    username: '',
    twoFactorAuthentication: 'none',
    secret: '',
    passwordExpired: ''
}

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(initializeUser)
    const [loading, setLoading] = useState(false)
    const pushEvent = useAnalyticsPushEvent()
    const navigate = useNavigate()

    const setAuthUser = (userData) => {
        setUser((prev) => ({
            ...prev,
            ...userData
        }))
    }

    const getCookiePartner = async () => {
        const rawPartner = localStorage.getItem('partner')
        if (rawPartner) {
            try {
                const partner = JSON.parse(rawPartner)
                return partner
            } catch (err) {
                return ''
            }
        }
    }

    const handleLogout = async () => {
        try {
            await Axios.post(PostAuthUserLogout())
            setUser(initializeUser)
            pushEvent('Logout')
        } catch (_) {
        } finally {
            Cookies.remove('token')
            localStorage.removeItem('partner')
            navigate('/login')
        }
    }

    const handleGetDetail = async (uuid) => {
        try {
            setLoading(true)
            const response = await Axios.get(GetUserDetail(uuid))
            if (response.status === 200) {
                // // set state
                setAuthUser(response.data.data)
            }
        } catch (_) {
            setAuthUser(initializeUser)
        } finally {
            setLoading(false)
        }
    }

    const value = {
        handleLogout,
        user,
        handleGetDetail,
        loading,
        getCookiePartner,
        setAuthUser
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthProvider }
export default AuthContext
