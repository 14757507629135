import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

const CustomInput = ({
    name,
    placeholder,
    type = 'text',
    value,
    onChange,
    icon,
    iconPosition = 'right',
    clickableIcon = false,
    onIconClick,
    inputClassName = '',
    minLength = 0,
    required = false,
    disabled,
    autoFocus = false,
    autoComplete = 'off',
    min,
    max,
    onlyAcceptNumbers = false,
    containerInputClassName = ''
}) => {
    const [inputType, setInputType] = useState(type)
    const { Control: Input } = Form

    const handleIconClick = () => {
        if (clickableIcon && onIconClick) {
            onIconClick()
        }

        if (clickableIcon && ['text', 'password'].includes(type)) {
            setInputType(inputType === 'password' ? 'text' : 'password')
        }
    }

    const handleChange = (e) => {
        if (type === 'text' && onlyAcceptNumbers) {
            if (/^\d*$/.test(e.target.value)) {
                onChange(e)
            }
        } else {
            onChange(e)
        }
    }

    return (
        <div className={containerInputClassName} style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            {iconPosition === 'left' && (
                <span
                    style={{ position: 'absolute', top: '8px', left: '10px', cursor: clickableIcon ? 'pointer' : 'default' }}
                    onClick={clickableIcon ? handleIconClick : () => {}}
                >
                    {icon}
                </span>
            )}
            <Input
                name={name}
                minLength={minLength}
                required={required}
                placeholder={placeholder}
                type={inputType}
                value={value || ''}
                onChange={(e) => handleChange(e)}
                style={{
                    paddingLeft: iconPosition === 'left' ? '30px' : '10px',
                    paddingRight: iconPosition === 'right' ? '30px' : '10px',
                    width: '100%'
                }}
                className={inputClassName}
                disabled={disabled}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
                min={min}
                max={max}
            />
            {iconPosition === 'right' && (
                <span
                    style={{ position: 'absolute', top: '8px', right: '10px', cursor: clickableIcon ? 'pointer' : 'default' }}
                    onClick={clickableIcon ? handleIconClick : () => {}}
                >
                    {icon}
                </span>
            )}
        </div>
    )
}

export default CustomInput
