import React, { useState } from 'react'

import { Alert, Button, Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faCheckCircle as iconChecked, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import CustomInput from '../Shared/CustomInput'

const App = (props) => {
    const [listShowPassword, setListShowPassword] = useState([])

    const {
        mergeStatement, statements,
        statementPasswords, handleChangeStatementPassword,
        processing, handlePreviewUploaded,
        setShowModalDeletedStatement,
        setSelectedDeletedStatement,
        statementErrorFilesMessage
    } = props

    const onDeleteClicked = (item) => {
        setSelectedDeletedStatement({ ...item })
        setShowModalDeletedStatement(true)
    }

    return (
        <Table responsive striped bordered hover className="mt-3">
            <thead>
                <tr>
                    <th>#</th>
                    <th>File</th>
                    <th className="col-md-6">Informasi</th>
                    <th>Dibuat</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {statements?.map((item, index) => (
                    <tr key={item.uuid}>
                        <td>{index + 1}</td>
                        <td>
                            <Button size="sm" variant="link" className="text-start text-decoration-none m-0 p-0"
                                onClick={() => handlePreviewUploaded(item)}
                            >{item.file_name}</Button>
                            {
                                (!mergeStatement?.processed_at || process.env.REACT_APP_MODE_ENV !== 'production') &&
                                    (
                                        <form>
                                            <CustomInput
                                                name="password"
                                                placeholder="password"
                                                type={listShowPassword.find(val => val.uuid === item.uuid)?.showPassword ? 'text' : 'password'}
                                                value={statementPasswords?.[item.uuid] || ''} disabled={processing}
                                                required={true}
                                                autoComplete="off"
                                                containerInputClassName="mt-3"
                                                onChange={(e) => handleChangeStatementPassword(item.uuid, e.target.value)}
                                                icon={<FontAwesomeIcon icon={listShowPassword.find(val => val.uuid === item.uuid)?.showPassword ? faEyeSlash : faEye} />}
                                                clickableIcon={!processing}
                                                onIconClick={() => {
                                                    if (listShowPassword.find(val => val.uuid === item.uuid)) {
                                                        setListShowPassword(prev =>
                                                            prev.map(val =>
                                                                val.uuid === item.uuid
                                                                    ? { ...val, showPassword: !val.showPassword }
                                                                    : val
                                                            )
                                                        )
                                                    } else {
                                                        setListShowPassword((prev) => [
                                                            ...prev,
                                                            {
                                                                uuid: item.uuid,
                                                                showPassword: true
                                                            }
                                                        ])
                                                    }
                                                }}
                                            />
                                            {
                                                statementErrorFilesMessage.find(val => val.uuid === item.uuid)
                                                    ? <Alert variant="danger" className="mt-3 p-1">
                                                        <span>{statementErrorFilesMessage.find(val => val.uuid === item.uuid).message}</span>
                                                    </Alert>
                                                    : null
                                            }
                                        </form>
                                    )
                            }
                        </td>
                        <td>
                            <Table striped bordered hover>
                                <thead>
                                    {
                                        item.detail_statements?.length > 0 &&
                                            (
                                                <tr>
                                                    <th>#</th>
                                                    <th>Bank</th>
                                                    <th>Nama Rekening</th>
                                                    <th>Nomor Rekening</th>
                                                    <th>Periode</th>
                                                </tr>
                                            )
                                    }
                                </thead>
                                <tbody>
                                    {
                                        item.detail_statements?.map((subItem, subIndex) => (
                                            <tr key={subIndex}>
                                                <td>{subIndex + 1}</td>
                                                <td className="text-nowrap">{subItem.bank_name}</td>
                                                <td>{subItem.bank_account_name}</td>
                                                <td className="text-nowrap">{subItem.bank_account_number}</td>
                                                <td className="text-nowrap">{subItem.period}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </td>
                        <td>{item.created_at}</td>
                        <td>
                            {
                                (!mergeStatement?.processed_at || process.env.REACT_APP_MODE_ENV !== 'production') &&
                                    (<Button size="sm" variant="link" className="text-decoration-none"
                                        onClick={() => onDeleteClicked(item)}
                                        disabled={processing}>
                                        <FontAwesomeIcon icon={faTrash} size="sm" className="text-danger" />
                                    </Button>)
                            }
                            {
                                (!!item.processed_at) &&
                                    (<FontAwesomeIcon icon={iconChecked} size="lg" className="text-success" />)
                            }
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    )
}

export default App
