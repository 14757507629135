export const chartColors = {
    blue: '#0d6efd',
    red: '#dc3545',
    green: '#198754',
    indigo: '#6610f2',
    pink: '#d63384',
    teal: '#20c997',
    purple: '#6f42c1',
    orange: '#fd7e14',
    yellow: '#ffc107',
    cyan: '#0dcaf0',
    gray: '#adb5bd'
}
