import React, {
    createContext,
    useContext,
    useEffect,
    useMemo
} from 'react'
import BrowserInteractionTime from 'browser-interaction-time'
import { createAmplitudeProvider } from './providers/amplitude'

const analyticsContext = createContext(null)

export const AnalyticsProvider = ({
    amplitudeApiKey,
    children
}) => {
    const value = useMemo(() => {
        const amplitudeProvider = createAmplitudeProvider({
            apiKey: amplitudeApiKey
        })

        return {
            pushEvent: (name, attributes) => {
                amplitudeProvider.pushEvent(name, attributes)
            },
            setUser: (user) => {
                amplitudeProvider.setUser(user)
            },
            view: (name, attributes) => {
                amplitudeProvider.view(name, attributes)
            }
        }
    }, [amplitudeApiKey])

    return (
        <analyticsContext.Provider value={value}>
            {children}
        </analyticsContext.Provider>
    )
}

/**
 * Returns a function to push an analytic event
 */
export const useAnalyticsPushEvent = () => {
    const context = useContext(analyticsContext)
    return context.pushEvent
}

/**
 * Returns a function to set user
 */
export const useAnalyticsSetUser = () => {
    const context = useContext(analyticsContext)
    return context.setUser
}

/**
 * Wrap a screen or section to track its usage
 */
export const AnalyticsView = ({
    name,
    attributes,
    children
}) => {
    const context = useContext(analyticsContext)

    useEffect(() => {
        context.view(name, attributes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useInteractionTime((milliseconds) => {
        context.pushEvent('View - End', {
            ...attributes,
            name,
            activeTimeInSeconds: Math.floor(milliseconds / 1000)
        })
    })

    return children
}

/**
 * Track active time spent while this hook
 * is rendered in a React component
 *
 * i.e Active window with regular user interactions
 * @param callback Called on cleanup with active time spent in milliseconds
 */
export const useInteractionTime = (
    callback
) => {
    useEffect(() => {
        const browserInteractionTime = new BrowserInteractionTime({
            idleTimeoutMs: 3000
        })

        browserInteractionTime.startTimer()

        return () => {
            browserInteractionTime.stopTimer()
            callback(browserInteractionTime.getTimeInMilliseconds())
        }
    }, [callback])
}
