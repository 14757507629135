import React, { useEffect } from 'react'

import { useAnalyticsPushEvent } from '../../../analytics'
import MergeApplicantContainer from './ApplicantContainer'

function App () {
    const pushEvent = useAnalyticsPushEvent()

    // Inits.
    useEffect(() => {
        pushEvent('Applicant Verification List')
    }, [pushEvent])

    return (
        <MergeApplicantContainer />
    )
}

export default App
