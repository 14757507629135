import React, { useContext } from 'react'
import { ToastContainer, Toast } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle as iconSuccess, faX as iconError } from '@fortawesome/free-solid-svg-icons'
import AppContext from '../../utils/context'
import './index.css'

function App () {
    const { notifications, setNotifications } = useContext(AppContext)

    const handleClose = (id) => {
        setNotifications((notifications) => {
            return notifications.filter((notification) => notification.id !== id)
        })
    }

    return (
        <ToastContainer containerPosition="fixed" position="top-end"
            className="p-3 mt-5 notification-container">
            {
                notifications.map((notification) => {
                    let iconBg = ''
                    if (notification.type.toUpperCase() === 'SUCCESS') {
                        iconBg = 'text-success'
                    } else if (notification.type.toUpperCase() === 'ERROR') {
                        iconBg = 'text-danger'
                    }

                    return (
                        <Toast key={notification.id}
                            onClose={() => handleClose(notification.id)}
                            show={true} autohide delay={3000}>
                            <Toast.Header>
                                <strong className={`me-auto ${iconBg}`}>
                                    {notification.type.toUpperCase() === 'SUCCESS'
                                        ? <FontAwesomeIcon icon={iconSuccess} size="lg" />
                                        : (notification.type.toUpperCase() === 'ERROR'
                                            ? <FontAwesomeIcon icon={iconError} size="lg" />
                                            : '')}
                                    <small className="px-1">{notification.type.toUpperCase()}</small>
                                </strong>
                            </Toast.Header>
                            <Toast.Body style={{ textAlign: 'left' }}>{notification.text}</Toast.Body>
                        </Toast>
                    )
                })
            }
        </ToastContainer>
    )
}

export default App
