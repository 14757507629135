import React, { useContext, useEffect, useState } from 'react'

import Axios from 'axios'

import AppContext from '../../../utils/context'
import CustomModal from '../../Shared/CustomModal'
import { GetApplicantDetail, DeleteApplicant } from '../../../utils/api'
import { useAnalyticsPushEvent } from '../../../analytics'
import { Col, Row } from 'react-bootstrap'

function App ({ showModal, setShowModal, selectedItem, onDeleted }) {
    const { pushNotification } = useContext(AppContext)
    const pushEvent = useAnalyticsPushEvent()

    // Delete statement.
    const [selected, setSelected] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleAction = () => {
        if (!selected) return

        async function fetchData () {
            try {
                setIsSubmitting(true)
                const { data: responseData } = await Axios.delete(DeleteApplicant(selected.uuid))
                pushNotification('success', responseData.message)
                setShowModal(false)

                if (onDeleted && typeof onDeleted === 'function') {
                    onDeleted(selected)
                }
            } catch (err) {
                pushNotification('error', null, err)
            } finally {
                setIsSubmitting(false)
            }
        }

        fetchData()
    }

    async function fetchDetail (item) {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetApplicantDetail(item.uuid))
            setSelected((prev) => ({
                ...prev,
                identity_number: responseData.data?.identity_number,
                name: responseData.data?.name,
                identity_url: responseData.data?.identity_url
            }))
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Inits.
    useEffect(() => {
        if (!selectedItem) return

        setSelected(selectedItem)
        fetchDetail(selectedItem)

        // Adds to amplitude.
        pushEvent('Confirm Delete Applicant', { clickedItem: selectedItem })
    }, [selectedItem])

    return (
        <CustomModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Hapus Verifikasi Pemohon"
            primaryButtonText="Hapus"
            primaryButtonAction={handleAction}
            primaryButtonVariant="danger"
            secondaryButtonDisabled={isSubmitting}
            primaryButtonDisabled={isSubmitting || loading}
            secondaryButtonText="Tutup"
            loading={isSubmitting}
        >
            Apakah Anda yakin ingin menghapus data Pemohon berikut?

            <div className="mt-3">
                <Row className="mb-3">
                    <Col sm="2"><strong>KTP</strong></Col>
                    <Col sm="10"><h5>{selected?.identity_number}</h5></Col>
                </Row>
                <Row className="mb-3">
                    <Col sm="2"><strong>Nama</strong></Col>
                    <Col sm="10"><h5>{selected?.name}</h5></Col>
                </Row>
            </div>
        </CustomModal>
    )
}

export default App
