import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { Alert, Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAnalyticsPushEvent } from '../../analytics'

const App = forwardRef(({ processing }, ref) => {
    const pushEvent = useAnalyticsPushEvent()

    // Processing filters.
    const [processingFilters, setProcessingFilters] = useState([''])
    const handleChangeProcessingFilter = (index, value) => {
        const clonedProcessingFilters = [...processingFilters]

        // Automatically breaks down comma into several inputs.
        const commaValues = value.split(',').map(x => x.trim()).filter(x => x)
        if (commaValues.length > 1) {
            clonedProcessingFilters.splice(index, 0, ...commaValues)
            setProcessingFilters(clonedProcessingFilters)
            return
        }

        clonedProcessingFilters[index] = value
        setProcessingFilters(clonedProcessingFilters)

        // Automatically push new filter at the end.
        if (index === clonedProcessingFilters.length - 1 && value?.trim() !== '') {
            clonedProcessingFilters.push('')
            setProcessingFilters([...clonedProcessingFilters])
            return
        }

        // Removes empty but not last index and have at least two filters.
        if (index !== clonedProcessingFilters.length - 1 && clonedProcessingFilters.length >= 2 && value?.trim() === '') {
            clonedProcessingFilters.splice(index, 1)
            setProcessingFilters([...clonedProcessingFilters])
        }
    }
    const handleBlurProcessingFilter = (index, value) => {
        const clonedProcessingFilters = [...processingFilters]
        clonedProcessingFilters[index] = value?.trim()
        setProcessingFilters([...clonedProcessingFilters])
    }
    const handleRemoveProcessingFilter = (index) => {
        const clonedProcessingFilters = [...processingFilters]
        clonedProcessingFilters.splice(index, 1)
        setProcessingFilters([...clonedProcessingFilters])
    }
    const handleUseCache = (cache) => {
        if (!cache?.filters?.length) return
        setProcessingFilters([...(cache?.filters || []), ''])

        // Adds to amplitude.
        pushEvent('Use Cache Buyer')
    }

    // Cache.
    const [filterCaches, setFiltersCaches] = useState([])
    const limitCache = 5
    const cacheName = 'dt-statement-buy-'
    const addNewCache = (filters) => {
        try {
            if (!filters || !filters.length) return
            const caches = filterCaches
            if (!caches) return
            if (caches.some(x => x.filters.join('') === filters.join(''))) return

            const nonEmptyFilters = filters.filter(x => x && x.trim())

            let cookieNames = Object.keys(localStorage)
            cookieNames = cookieNames.filter(name => name.startsWith(cacheName))

            const removedCookieNames = cookieNames.slice(0, -(limitCache - 1))
            removedCookieNames.forEach((cookieName) => {
                localStorage.removeItem(cookieName)
            })

            const cookieName = `${cacheName}${Math.floor(new Date().getTime() / 1000)}`
            localStorage.setItem(cookieName, JSON.stringify(nonEmptyFilters))
        } catch (err) {
        }
    }

    const getFiltersCaches = () => {
        try {
            let cookieNames = Object.keys(localStorage)
            cookieNames = cookieNames.filter(name => name.startsWith(cacheName))
            const caches = cookieNames.reverse().map(name => {
                const filtersStr = localStorage.getItem(name)
                if (!filtersStr) return {}

                const filters = JSON.parse(filtersStr)
                return {
                    name,
                    filters
                }
            })
            return caches
        } catch (err) {
            return []
        }
    }

    // Inits.
    useEffect(() => {
        const caches = getFiltersCaches()
        setFiltersCaches(caches)
    }, [])

    useImperativeHandle(ref, () => ({
        getProcessingFilters: () => { return processingFilters },
        getFilterCaches: () => { return filterCaches },
        postAction: (filters) => {
            addNewCache(filters)
        }
    }))

    return (
        <>
            <p>
                Silakan masukkan filter untuk Buyer pada inputan berikut
            </p>
            <Row>
                {processingFilters?.map((item, index) => (
                    <Col xs={4} key={index} className="mt-1">
                        <InputGroup>
                            <Form.Control type="text" placeholder="contoh: PT Buyer Sejahtera Bersama"
                                value={item} disabled={processing}
                                autoFocus={index === 0}
                                onChange={(e) => handleChangeProcessingFilter(index, e.target.value)}
                                onBlur={(e) => handleBlurProcessingFilter(index, e.target.value)}
                            />
                            {
                                index !== processingFilters.length - 1 &&
                                (<Button variant="danger" tabIndex={-1} disabled={processing}
                                    onClick={() => handleRemoveProcessingFilter(index)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>)
                            }
                        </InputGroup>
                    </Col>
                ))}
            </Row>
            {
                processingFilters?.filter(x => x)?.length > 2 &&
                (<Button size="sm" variant="outline-danger" className="m-1" disabled={processing}
                    onClick={() => setProcessingFilters([''])}>
                    Hapus Semua Filter
                </Button>)
            }

            <Alert variant="info" className="my-3">
                Copy paste <i>comma separated values</i> akan otomatis membagi menjadi beberapa inputan.
            </Alert>
            {
                (filterCaches?.length > 0 && !processing) &&
                (<Card>
                    <Card.Header>Histori Buyer Filter (yang pernah Anda gunakan)</Card.Header>
                    <Card.Body>
                        {filterCaches.map((cache, index) => (
                            <InputGroup size="sm" className="mt-1" key={index}>
                                <Button variant="outline-secondary" disabled={processing}
                                    onClick={() => handleUseCache(cache)}>
                                    Gunakan Filter
                                </Button>
                                <Form.Control
                                    disabled
                                    value={cache.filters.join(', ')}
                                />
                            </InputGroup>
                        ))}
                    </Card.Body>
                </Card>)
            }
        </>
    )
})

export default App
