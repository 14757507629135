import React, { useEffect, useState } from 'react'

import { ProgressBar } from 'react-bootstrap'

import AnimatedHourGlass from '../Shared/AnimatedHourGlass'

function App ({ mergeStatement, onTimerCompleted }) {
    // Timer.
    const calculateTimeLeft = (ms) => {
        const targetDate = ms?.fine_tuning_estimation_at
        if (!targetDate) {
            return { hours: '00', minutes: '00', seconds: '00', diff: 0 }
        }

        const targetTime = new Date(targetDate).getTime()
        const currentTime = new Date().getTime()
        const timeDifference = targetTime - currentTime

        if (timeDifference <= 0) {
            if (onTimerCompleted && typeof onTimerCompleted === 'function') {
                onTimerCompleted()
            }

            return { hours: '00', minutes: '00', seconds: '00', diff: 0 }
        }

        const hours = String(Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0')
        const minutes = String(Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0')
        const seconds = String(Math.floor((timeDifference % (1000 * 60)) / 1000)).padStart(2, '0')

        return {
            hours,
            minutes,
            seconds,
            diff: Math.floor(timeDifference / 1000)
        }
    }
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(null))
    const [timeLeftInitDiff, setTimeLeftInitDiff] = useState(0)

    useEffect(() => {
        const targetTime = new Date(mergeStatement?.fine_tuning_estimation_at).getTime()
        const currentTime = new Date().getTime()
        const timeDifference = Math.floor((targetTime - currentTime) / 1000)
        setTimeLeftInitDiff(timeDifference)

        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft(mergeStatement))
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, [mergeStatement])

    return (
        <>
            {/* { (timeLeft?.diff > 0) && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="p-2">
                        Mohon menunggu sembari proses Fine Tuning sedang berjalan. <br/>
                    </div>
                    <Row>
                        <Col>
                            <h1>{timeLeft?.minutes}</h1>
                            <span>Menit</span>
                        </Col>
                        <Col>
                            <h1>{timeLeft?.seconds}</h1>
                            <span>Detik</span>
                        </Col>
                    </Row>
                </div>
            )} */}
            { (timeLeft?.diff > 0) && (
                <>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <h4>Mohon menunggu proses Fine Tuning yang sedang berjalan.
                            <div className='float-end ms-3'>
                                <AnimatedHourGlass />
                            </div>
                        </h4>
                    </div>
                    <ProgressBar animated now={timeLeftInitDiff - (timeLeft?.diff || 0)} max={timeLeftInitDiff} className="mb-3" />
                </>
            )}
        </>
    )
}

export default App
