import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { useAnalyticsPushEvent } from '../../analytics'
import SlikDeleteModal from './SlikDeleteModal'
import SlikTableData from './SlikTableData'

const App = forwardRef(({ mergeSlik, sliks, fetchFilesSlik, processing }, ref) => {
    const pushEvent = useAnalyticsPushEvent()

    const handlePreviewUploaded = (item) => {
        pushEvent('Preview Uploaded File', {
            clickedItem: item
        })
        window.open(item.file_url)
    }

    // Delete slik.
    const [showModalDeletedSlik, setShowModalDeletedSlik] = useState(false)
    const [selectedDeletedSlik, setSelectedDeletedSlik] = useState(null)

    // Passwords.
    const [slikPasswords, setSlikPasswords] = useState({})
    const handleChangeSlikPassword = (uuid, value) => {
        const newSlikPasswords = { ...slikPasswords }
        newSlikPasswords[uuid] = value
        setSlikPasswords(newSlikPasswords)
    }

    const onSlikDeleted = (deleted) => {
        fetchFilesSlik(mergeSlik)

        // Adds to amplitude.
        pushEvent('Slik File deleted', { clickedItem: deleted })
    }

    // Inits.
    useEffect(() => {
        const maps = {}
        sliks.forEach(x => {
            maps[x.uuid] = x.password || ''
        })
        setSlikPasswords(maps)
    }, [sliks])

    useImperativeHandle(ref, () => ({
        getSlikPasswords: () => { return slikPasswords }
    }))

    return (
        <>
            <SlikTableData
                mergeSlik={mergeSlik} sliks={sliks}
                slikPasswords={slikPasswords} handleChangeSlikPassword={handleChangeSlikPassword}
                processing={processing} handlePreviewUploaded={handlePreviewUploaded}
                setShowModalDeletedSlik={setShowModalDeletedSlik}
                setSelectedDeletedSlik={setSelectedDeletedSlik}
            />

            <SlikDeleteModal
                showModal={showModalDeletedSlik}
                setShowModal={setShowModalDeletedSlik}
                selectedItem={selectedDeletedSlik}
                onFileDeleted={onSlikDeleted}>
            </SlikDeleteModal>
        </>
    )
})

export default App
