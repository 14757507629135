import React from 'react'
import { Row, Col, Image, Spinner } from 'react-bootstrap'
import './index.css'

const Guidelines2FA = ({ loadingQRCode, qrcode = '' }) => {
    return (
        <Row className="mt-4">
            <h3 className="mb-3">Aktifkan 2FA</h3>
            {!loadingQRCode
                ? (
                    <Col>
                        <ol>
                            <li>
                                <Row>
                                    <Col md={8} lg={8} xl={8} xs={12} sm={12}>
                                        <p>Anda akan memerlukan aplikasi autentikator di ponsel untuk menyelesaikan proses ini, seperti salah satu berikut:</p>
                                        <ul>
                                            <li>Google Authenticator</li>
                                            <li>Microsoft Authenticator</li>
                                            <li>Authy</li>
                                        </ul>
                                        <Image src={qrcode} className="object-fit-cover guideline-qrcode" />
                                    </Col>
                                </Row>
                            </li>
                            <li>
                                <Row>
                                    <Col>
                                        <p>Setelah memindai kode QR di atas, masukkan kode enam digit yang dihasilkan oleh autentikator Anda</p>
                                    </Col>
                                </Row>
                            </li>
                        </ol>
                    </Col>
                )
                : (
                    <Col>
                        <Spinner size="lg" animation="border" variant="primary" />
                    </Col>
                )}
        </Row>
    )
}

export default Guidelines2FA
