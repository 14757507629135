import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { useAnalyticsPushEvent } from '../../analytics'

const TitledFolder = ({ files, parent, selected }) => {
    const pushEvent = useAnalyticsPushEvent()
    const handleTileClick = (file) => {
        // add to amplitude
        pushEvent(`Click ${parent === 'Merge' ? ' Merge ' : ''} Result`, {
            clickedFile: file,
            clickedItem: {
                uuid: selected.uuid,
                created_at: selected.created_at,
                processed_at: selected.processed_at
            }
        })
        window.open(file.url)
    }
    return (
        <Row xs={1} sm={2} md={4} lg={5} className="m-2">
            {files?.map((file, index) => (
                <Col key={index} className="border border-dark bg-light rounded m-1 p-2 text-center">
                    <Button size="sm" variant="link" className="text-decoration-none m-0 p-0 text-secondary"
                        onClick={() => handleTileClick(file)}>
                        <div>
                            <FontAwesomeIcon icon={faFile} size="4x" className="py-2" />
                        </div>
                        <strong>{file.name?.replace(/_/g, ' ').toUpperCase()}</strong>
                    </Button>
                </Col>
            ))}
        </Row>
    )
}

export default TitledFolder
