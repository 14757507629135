import amplitude from 'amplitude-js'
// import { AnalyticsProvider, Attributes } from '../types';
import { urlSearchParamsToObject } from '../utils'

export const createAmplitudeProvider = ({
    apiKey
    // user
}) => {
    const instance = amplitude.getInstance()
    instance.init(apiKey)
    // instance.setUserId(user.id);

    // if (user.attributes) {
    //     instance.setUserProperties(user.attributes);
    // }

    return {
        setUser: (user) => {
            instance.setUserId(user.uuid)
            instance.setUserProperties(user)
        },
        pushEvent: (name, attributes) => {
            instance.logEvent(name, attributes)
        },
        view: (name, attributes) => {
            instance.logEvent(`View ${name}`, {
                name,
                url: window.location.href,
                path: window.location.pathname,
                search: urlSearchParamsToObject(
                    new URLSearchParams(window.location.search)
                ),
                ...attributes
            })
        }
    }
}
