import React, { useContext, useEffect, useState } from 'react'

import Axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'

import AppContext from '../../utils/context'
import { GetMergeStatementList } from '../../utils/api'
import { useAnalyticsPushEvent } from '../../analytics'
import TablePagination from '../Shared/TablePagination'
import MergeStatementTableData from './MergeStatementTableData'
import MergeStatementDeleteModal from './MergeStatementDeleteModal'
import MergeStatementProcessModal from './MergeStatementProcessModal'
import MergeStatementShowResultModal from './MergeStatementShowResultModal'

function App ({ triggerRefreshTable }) {
    // Gets default value from query params.
    const defaultParams = { page: 1, limit: 20 }
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const page = queryParams.get('page')
    if (!Number.isNaN(Number.parseInt(page, 10)) && page > 0) {
        defaultParams.page = page
    }

    const { pushNotification } = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [listParams, setListParams] = useState(defaultParams)
    const [data, setData] = useState([])
    async function fetchMergeStatement () {
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetMergeStatementList(), { params: { ...listParams, type: 'consumptive' } })
            setData(responseData)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    const pushEvent = useAnalyticsPushEvent()

    // Show result statement.
    const [showModalShowResultStatement, setShowModalShowResultStatement] = useState(false)
    const [selectedShowResultStatement, setSelectedShowResultStatement] = useState(null)

    // Process statement.
    const [showModalProcessedStatement, setShowModalProcessedStatement] = useState(false)
    const [selectedProcessedStatement, setSelectedProcessedStatement] = useState(null)

    // Delete statement.
    const [showModalDeletedStatement, setShowModalDeletedStatement] = useState(false)
    const [selectedDeletedStatement, setSelectedDeletedStatement] = useState(null)

    const onDeleted = (deleted) => {
        setSelectedShowResultStatement(null)
        setSelectedProcessedStatement(null)
        setSelectedDeletedStatement(null)
        fetchMergeStatement()

        // Adds to amplitude.
        pushEvent('Statement Folder deleted', { clickedItem: deleted })
    }

    // Inits.
    useEffect(() => {
        fetchMergeStatement()
    }, [listParams])
    useEffect(() => {
        if (triggerRefreshTable) {
            setListParams((prev) => ({ ...prev, page: 1 }))
        }
    }, [triggerRefreshTable])

    return (
        <>
            { loading && (
                <div className="mt-3 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && (
                <>
                    <TablePagination
                        className="mt-3"
                        limit={listParams?.limit}
                        page={listParams?.page}
                        meta={data?.meta}
                        onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
                    />
                    <MergeStatementTableData
                        listParams={listParams} listItems={data?.data}
                        setShowModalShowResultStatement={setShowModalShowResultStatement}
                        setSelectedShowResultStatement={setSelectedShowResultStatement}
                        setShowModalProcessedStatement={setShowModalProcessedStatement}
                        setSelectedProcessedStatement={setSelectedProcessedStatement}
                        setShowModalDeletedStatement={setShowModalDeletedStatement}
                        setSelectedDeletedStatement={setSelectedDeletedStatement}
                    />
                    <TablePagination
                        limit={listParams?.limit}
                        page={listParams?.page}
                        meta={data?.meta}
                        onPageChange={newParams => setListParams(prev => ({ ...prev, ...newParams }))}
                    />

                    <MergeStatementShowResultModal
                        showModal={showModalShowResultStatement}
                        setShowModal={setShowModalShowResultStatement}
                        selectedItem={selectedShowResultStatement}>
                    </MergeStatementShowResultModal>

                    <MergeStatementProcessModal
                        showModal={showModalProcessedStatement}
                        setShowModal={setShowModalProcessedStatement}
                        selectedItem={selectedProcessedStatement}
                        onProcessCompleted={fetchMergeStatement}>
                    </MergeStatementProcessModal>

                    <MergeStatementDeleteModal
                        showModal={showModalDeletedStatement}
                        setShowModal={setShowModalDeletedStatement}
                        selectedItem={selectedDeletedStatement}
                        onDeleted={onDeleted}>
                    </MergeStatementDeleteModal>
                </>
            )}
        </>
    )
}

export default App
