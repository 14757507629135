import React from 'react'

import { Button, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faTrash, faMoneyBill1Wave, faEye, faCircleXmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const App = (props) => {
    const navigate = useNavigate()
    const {
        listParams, listItems,
        setShowModalIncomeVerification,
        setSelectedShowIncomeVerification,
        setShowModalDeleteApplicant,
        setSelectedDeletedApplicant,
        setShowModalEKYCProcess,
        setSelectedEKYCProcess
    } = props

    const onShowIncomeVerification = (item, typeModal = '') => {
        setSelectedShowIncomeVerification({ ...item, type_modal: typeModal })
        setShowModalIncomeVerification(true)
    }

    const onDeleteClicked = (item) => {
        setSelectedDeletedApplicant({ ...item })
        setShowModalDeleteApplicant(true)
    }

    const onEKYCProcess = (item, typeModal = '') => {
        setSelectedEKYCProcess({ ...item, type_modal: typeModal })
        setShowModalEKYCProcess(true)
    }

    return (
        <Table responsive striped bordered hover>
            <thead>
                <tr>
                    <th style={{ width: '1%' }}>#</th>
                    <th style={{ width: '100px' }}>No KTP</th>
                    <th>Nama</th>
                    <th style={{ width: '100px' }}>Dibuat</th>
                    <th colSpan={5} className="text-center">Tindakan</th>
                </tr>
            </thead>
            <tbody>
                {listItems?.map((item, index) => (
                    <tr key={item.uuid}>
                        <td>{((((listParams?.page || 1) - 1)) * (listParams?.limit || 0)) + (index + 1)}</td>
                        <td>{item.identity_number}</td>
                        <td>{item.name}</td>
                        <td>{item.created_at}</td>
                        <td style={{ width: '140px' }} className="text-center">
                            <Button size="sm" variant="link" className="text-decoration-none"
                                onClick={() => navigate(`/applicant-verification/information/${item.uuid}`)}>
                                <FontAwesomeIcon icon={faEye} size="lg" />
                                <p className="m-1 mb-0">Lihat Pemohon</p>
                            </Button>
                        </td>
                        <td style={{ width: '90px' }} className="text-center">
                            {
                                item.ekyc && item.kyc_status === 'none'
                                    ? <Button size="sm" variant="link" className="text-decoration-none"
                                        onClick={() => onEKYCProcess(item)}>
                                        <FontAwesomeIcon icon={faFile} size="lg" />
                                        <p className="m-1 mb-0">E-KYC</p>
                                    </Button>
                                    : <></>
                            }
                            {
                                item.ekyc && item.kyc_status === 'verified'
                                    ? <Button size="sm" variant="link" className="text-success text-decoration-none"
                                        onClick={() => onEKYCProcess(item, 'view_detail')}>
                                        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                        <p className="m-1 mb-0">E-KYC</p>
                                    </Button>
                                    : <></>
                            }
                            {
                                item.ekyc && item.kyc_status === 'not_verified'
                                    ? <Button size="sm" variant="link" className="text-danger text-decoration-none"
                                        onClick={() => onEKYCProcess(item, 'view_detail')}>
                                        <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                        <p className="m-1 mb-0">E-KYC</p>
                                    </Button>
                                    : <></>
                            }
                        </td>
                        <td style={{ width: '1%' }} className="text-center">
                            {
                                item.ekyc && item.income_status === 'none'
                                    ? <Button size="sm" variant="link" className="text-decoration-none"
                                        onClick={() => onShowIncomeVerification(item)}>
                                        <FontAwesomeIcon icon={faMoneyBill1Wave} size="lg" />
                                        <p className="m-1 mb-0">Verifikasi Pendapatan</p>
                                    </Button>
                                    : <></>
                            }
                            {
                                item.ekyc && item.income_status === 'verified'
                                    ? <Button size="sm" variant="link" className="text-success text-decoration-none"
                                        onClick={() => onShowIncomeVerification(item, 'view_detail')}>
                                        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
                                        <p className="m-1 mb-0">Verifikasi Pendapatan</p>
                                    </Button>
                                    : <></>
                            }

                            {
                                item.ekyc && item.income_status === 'not_verified'
                                    ? <Button size="sm" variant="link" className="text-danger text-decoration-none"
                                        onClick={() => onShowIncomeVerification(item, 'view_detail')}>
                                        <FontAwesomeIcon icon={faCircleXmark} size="lg" />
                                        <p className="m-1 mb-0">Verifikasi Pendapatan</p>
                                    </Button>
                                    : <></>
                            }
                        </td>
                        <td style={{ width: '1%' }} className="text-center">
                            <Button size="sm" variant="link" className="text-decoration-none"
                                onClick={() => onDeleteClicked(item)}>
                                <FontAwesomeIcon icon={faTrash} size="lg" className="text-danger" />
                                <p className="m-1 mb-0">Hapus</p>
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table >
    )
}

export default App
