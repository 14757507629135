import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

function CustomModal (props) {
    const {
        size,
        show, handleClose, backdrop = 'static',
        title,
        primaryButtonText, secondaryButtonText, secondaryButtonDisabled = false,
        primaryButtonAction, primaryButtonVariant, primaryButtonDisabled = false,
        children,
        footerLeftChildren,
        loading,
        keyboard = true,
        closeButton = true
    } = props

    const computedLoading = loading || false

    return (
        <Modal size={size || 'md'} show={show} onHide={handleClose} backdrop={backdrop} keyboard={keyboard} onExit={handleClose}>
            <Modal.Header closeButton={closeButton}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {
                (secondaryButtonText || primaryButtonText) &&
                (<Modal.Footer className="justify-content-between">
                    <div>{footerLeftChildren}</div>
                    <div>
                        {
                            secondaryButtonText &&
                            (<Button size="sm" variant="secondary" className="m-1" onClick={handleClose} disabled={secondaryButtonDisabled}>
                                {secondaryButtonText}
                            </Button>)
                        }
                        {
                            primaryButtonText &&
                            (<Button size="sm" variant={primaryButtonVariant} className="m-1" onClick={primaryButtonAction} disabled={computedLoading === true || primaryButtonDisabled}>
                                {primaryButtonText}
                                {computedLoading === true && (<FontAwesomeIcon icon={faSpinner} spin size="lg" className="ms-2"></FontAwesomeIcon>)}
                            </Button>)
                        }
                    </div>
                </Modal.Footer>)
            }
        </Modal>
    )
}

export default CustomModal
