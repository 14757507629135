import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AppProvider } from './utils/context'
import Notification from './components/Notification'
import App from './pages/App'
import Login from './pages/login'
import Register from './pages/register'
import PrivateRoute from './middlewares/PrivateRoute'
import { AuthProvider } from './contexts/auth'
import { AnalyticsProvider } from './analytics'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

function Element () {
    const [notifications, setNotifications] = useState([])

    return <AnalyticsProvider
        amplitudeApiKey={process.env.REACT_APP_AMPLITUDE_KEY}
    >
        <AppProvider value={{ notifications, setNotifications }}>
            <Notification />
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route exact path="/*" element={
                            <PrivateRoute>
                                <App />
                            </PrivateRoute>
                        } />
                        <Route path="/*" element={<App />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </AppProvider>
    </AnalyticsProvider>
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Element />)
