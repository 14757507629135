import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function SiteMap ({ menu = [] }) {
    return (
        <Breadcrumb>
            {menu.map((item) =>
                <Breadcrumb.Item key={item.name} linkProps={{ to: item.link }} linkAs={Link}>
                    {item.name}
                </Breadcrumb.Item>
            )}
        </Breadcrumb>
    )
}

export default SiteMap
